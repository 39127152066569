<template>
  <div>
    <img class="popup-index-code" :src="detailSrc" alt="" />
    <div class="popup-index-msg">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    detailType: {
      type: String,
      default: "",
    },
    detailSrc: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/c_popup.scss";
</style>